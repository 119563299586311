import React, {useState, useCallback, useEffect, useRef} from 'react';
import {AuthContext} from './AuthContext';
import {useLogout, useVerifyToken} from 'src/api/Auth/Auth';

// Constantes en dehors du composant
const MIN_VERIFY_INTERVAL = 4 * 60 * 1000; // 4 minutes
const INITIAL_AUTH_STATE = {
  isAuthenticated: false,
  userRole: null,
  userId: null,
  emailVerified: false,
  isOnboardingCompleted: false,
  loading: false,
};

export const AuthProvider = ({children}) => {
  // Initialisation de l'état avec localStorage
  const [authState, setAuthState] = useState(() => {
    const storedAuthState = localStorage.getItem('authState');
    return storedAuthState ? JSON.parse(storedAuthState) : INITIAL_AUTH_STATE;
  });

  // Refs pour la gestion des vérifications
  const verifyTimerRef = useRef(null);
  const lastVerifyRef = useRef(0);
  const isVerifyingRef = useRef(false);

  // Hooks
  const logoutHook = useLogout();
  const verifyToken = useVerifyToken();

  // Gestionnaire de mise à jour de l'état d'authentification
  const setAuthInfo = useCallback(({userId, role, emailVerified, isOnboardingCompleted}) => {
    const newState = {
      isAuthenticated: true,
      userRole: role,
      userId,
      emailVerified,
      isOnboardingCompleted,
      loading: false,
    };
    localStorage.setItem('authState', JSON.stringify(newState));
    setAuthState(newState);
  }, []);

  // Fonction de déconnexion
  const logout = useCallback(() => {
    if (verifyTimerRef.current) {
      clearInterval(verifyTimerRef.current);
      verifyTimerRef.current = null;
    }

    logoutHook();
    localStorage.setItem('recentlyLoggedOut', 'true');
    localStorage.removeItem('authState');
    setAuthState(INITIAL_AUTH_STATE);
  }, [logoutHook]);

  // Fonction de vérification throttled
  const throttledVerifyToken = useCallback(async (force = false) => {
    const now = Date.now();

    // Vérifier si on peut exécuter la vérification
    if (isVerifyingRef.current ||
        (!force && now - lastVerifyRef.current < MIN_VERIFY_INTERVAL)) {
      return;
    }

    try {
      isVerifyingRef.current = true;
      lastVerifyRef.current = now;
      await verifyToken();
    } catch (error) {
      console.error('Erreur lors de la vérification du token:', error);
    } finally {
      isVerifyingRef.current = false;
    }
  }, [verifyToken]);

  // Effet pour gérer la vérification périodique
  useEffect(() => {
    // Nettoyer l'intervalle existant
    if (verifyTimerRef.current) {
      clearInterval(verifyTimerRef.current);
      verifyTimerRef.current = null;
    }

    if (authState.isAuthenticated) {
      // Vérification initiale forcée
      throttledVerifyToken(true);

      // Mettre en place la vérification périodique
      verifyTimerRef.current = setInterval(() => {
        throttledVerifyToken(false);
      }, MIN_VERIFY_INTERVAL);
    }

    // Cleanup à la désactivation du composant
    return () => {
      if (verifyTimerRef.current) {
        clearInterval(verifyTimerRef.current);
        verifyTimerRef.current = null;
      }
    };
  }, [authState.isAuthenticated, throttledVerifyToken]);

  // Effet pour gérer le focus de la fenêtre
  useEffect(() => {
    const handleFocus = () => {
      if (authState.isAuthenticated) {
        throttledVerifyToken(true);
      }
    };

    // Ajouter l'écouteur d'événement
    window.addEventListener('focus', handleFocus);

    // Cleanup
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [authState.isAuthenticated, throttledVerifyToken]);

  return (
    <AuthContext.Provider value={{
      ...authState,
      setAuthInfo,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};
