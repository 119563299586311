import merge from 'lodash/merge';
import {fr as frFRAdapter} from 'date-fns/locale';
import {frFR as frFRCore} from '@mui/material/locale';
import {frFR as frFRDate} from '@mui/x-date-pickers/locales';
import {frFR as frFRDataGrid} from '@mui/x-data-grid';

export const allLangs = [
  {
    label: 'Français',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
  },
];

export const defaultLang = allLangs[0]; // Français uniquement
/* import merge from 'lodash/merge';
// adaptateurs de date
import {
  fr as frFRAdapter,
  enUS as enUSAdapter,
  nl as nlNLAdapter,
} from 'date-fns/locale';
// composants de base MUI
import {
  frFR as frFRCore,
  enUS as enUSCore,
  nlNL as nlNLCore,
} from '@mui/material/locale';
// sélecteurs de date
import {
  frFR as frFRDate,
  enUS as enUSDate,
  nlNL as nlNLDate,
} from '@mui/x-date-pickers/locales';
// grille de données
import {
  frFR as frFRDataGrid,
  enUS as enUSDataGrid,
  nlNL as nlNLDataGrid,
} from '@mui/x-data-grid';

export const allLangs = [
  {
    label: 'Français',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Nederlands',
    value: 'nl',
    systemValue: merge(nlNLDate, nlNLDataGrid, nlNLCore),
    adapterLocale: nlNLAdapter,
    icon: 'flagpack:nl',
  },
];

export const defaultLang = allLangs[0]; // Français par défaut */

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
