import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
// utilitaires
/* import {localStorageGetItem} from 'src/utils/storageAvailable'; */
// configuration
/* import {defaultLang} from './config-lang'; */
// fichiers de traduction
import traductionFr from './langs/fr.json';
import traductionEn from './langs/en.json';
import traductionNl from './langs/nl.json';

/* const lng = localStorageGetItem('i18nextLng', defaultLang.value); */
const lng = 'fr';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        fr: {translation: traductionFr},
        en: {translation: traductionEn},
        nl: {translation: traductionNl},
      },
      lng,
      fallbackLng: 'fr',
      debug: false,
      ns: ['translation'],
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false,
      },
    });

export default i18n;
